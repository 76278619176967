var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap popup_wrap_pdf",
      staticStyle: { width: "800px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            _vm._s(_vm.$t("msg.CMBA380.030")) +
              " " +
              _vm._s(_vm.$t("msg.CMBA380.031"))
          ),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00335")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.150")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.151")))]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm.userList.length != 0
                  ? _vm._l(_vm.userList, function (item, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", { staticClass: "text_left" }, [
                          _vm._v(_vm._s(item.usrNm)),
                        ]),
                        _c("td", { staticClass: "text_left" }, [
                          _vm._v(_vm._s(item.usrPhone)),
                        ]),
                        _c("td", { staticClass: "text_left" }, [
                          _vm._v(_vm._s(item.usrEmail)),
                        ]),
                      ])
                    })
                  : [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "99" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CMBA380.029"))),
                        ]),
                      ]),
                    ],
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ADMN040G010.032")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "40%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }